import { Button, Divider, Typography } from '@mui/material';
import { useState } from 'react';

import { ConditionEditor } from '@/components/conditions/ConditionEditor';
import {
  apps,
  accountId,
  dataClasses,
  environment,
  resourceName,
  resourceType,
  sourceRegion,
  subnets,
  tags,
  vpc,
  resourceId,
  cloudProvider,
  resourceGroupName,
} from '@/components/queryBuilder/properties';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { RenameEntityName } from '@/components/wizard/renameEntityName';
import { type FilterProperty } from '@/types/advanceFilter';

import type { BackupPolicyEditState } from './BackupPolicyCreationFlow';
import BackupPolicyManualRule, {
  BackupPolicyManualRules,
} from './BackupPolicyManualRule';
import BackupPolicyManualRuleEditor, {
  ResourceOperation,
} from './BackupPolicyManualRuleEditor';

export const policySupportedProperties: FilterProperty[][] = [
  [resourceType, cloudProvider, dataClasses, environment, apps],
  [accountId, sourceRegion, vpc, subnets, resourceGroupName],
  [resourceName, resourceId, tags],
];

export const BackupPolicyConditionEditor = (
  props: StepProps<BackupPolicyEditState>
) => {
  const [addIncExc, setAddIncExc] = useState(false);
  return (
    <>
      <StepContainer
        stepperLabels={props.stepperLabels}
        stepperIndex={0}
        onBackClick={props.back}
        canGoNext={() => true}
        onNextClick={() => {
          props.setNextStep(props.currentStep.next?.[0]);
        }}
        navigationComponent={
          <RenameEntityName
            label='Backup policy name'
            name={props.wizardState.name}
            onChange={(name) =>
              props.setWizardState((state) => ({ ...state, name }))
            }
          />
        }
      >
        <div>
          <Typography variant='body1' sx={{ mb: '24px' }}>
            Back up resources based on these conditions:
          </Typography>
          <ConditionEditor
            options={{
              supportGrouping: true,
              supportedProperties: policySupportedProperties,
            }}
            condition={props.wizardState.condition}
            onChange={(condition) =>
              props.setWizardState({ ...props.wizardState, condition })
            }
          />
          <Divider />
          <Typography variant='body1' sx={{ mt: '24px', mb: '24px' }}>
            Include or exclude these resources:
          </Typography>
          {props.wizardState.include?.length &&
          props.wizardState.include.length > 0 ? (
            <>
              <Typography variant='body2' sx={{ mb: '12px' }}>
                Include:
              </Typography>
              <BackupPolicyManualRules>
                {props.wizardState.include?.map((resource) => {
                  return (
                    <BackupPolicyManualRule
                      resourceId={resource}
                      onDelete={(resourceId) => {
                        props.setWizardState({
                          ...props.wizardState,
                          include: props.wizardState.include?.filter(
                            (r) => r !== resourceId
                          ),
                        });
                      }}
                      key={resource}
                    />
                  );
                })}
              </BackupPolicyManualRules>
            </>
          ) : (
            <></>
          )}
          {props.wizardState.exclude?.length &&
          props.wizardState.exclude.length > 0 ? (
            <>
              <Typography variant='body2' sx={{ mt: '24px', mb: '12px' }}>
                Exclude:
              </Typography>
              <BackupPolicyManualRules>
                {props.wizardState.exclude?.map((resource) => {
                  return (
                    <BackupPolicyManualRule
                      resourceId={resource}
                      key={resource}
                      onDelete={(resourceId) => {
                        props.setWizardState({
                          ...props.wizardState,
                          exclude: props.wizardState.exclude?.filter(
                            (r) => r !== resourceId
                          ),
                        });
                      }}
                    />
                  );
                })}
              </BackupPolicyManualRules>
            </>
          ) : (
            <></>
          )}

          <div style={{ marginTop: '24px' }}>
            {addIncExc && (
              <BackupPolicyManualRuleEditor
                onFinish={(operation, resource) => {
                  if (resource && resource.length > 8) {
                    if (operation === ResourceOperation.Include) {
                      props.setWizardState({
                        ...props.wizardState,
                        include: [
                          ...(props.wizardState.include || []),
                          resource,
                        ],
                      });
                    } else {
                      props.setWizardState({
                        ...props.wizardState,
                        exclude: [
                          ...(props.wizardState.exclude || []),
                          resource,
                        ],
                      });
                    }
                  }

                  setAddIncExc(false);
                }}
                onAbort={() => {
                  setAddIncExc(false);
                }}
              />
            )}
            {!addIncExc && (
              <Button
                onClick={() => {
                  setAddIncExc(true);
                }}
                variant='outlined'
                sx={{
                  borderColor: 'transparent',
                  width: 32,
                  height: 32,
                  padding: 0,
                  minWidth: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i className='material-symbols-add-rounded text-xl' />
              </Button>
            )}
          </div>
        </div>
      </StepContainer>
    </>
  );
};
