import { CardContent, Divider, Stack, type SxProps } from '@mui/material';
import type { PropsWithChildren } from 'react';

import { WizardStepper } from './wizardStepper';

import { PanelFooter } from '../panels/panelFooter';

type StepContainerProps =
  | PropsWithChildren<{
      onBackClick: () => void;
      onNextClick: () => void;
      canGoNext: () => boolean;
      nextButtonText?: string;
      backButtonText?: string;
      hideNavigation?: false;
      hideStepper?: boolean;
      stepperLabels?: string[];
      stepperIndex?: number;
      sx?: SxProps;
      navigationComponent?: React.ReactNode;
      toolbarComponent?: React.ReactNode;
    }>
  | PropsWithChildren<{
      hideNavigation: true;
      hideStepper?: boolean;
      stepperLabels?: string[];
      stepperIndex: number;
      sx?: SxProps;
      toolbarComponent?: React.ReactNode;
    }>;

export const StepContainer = (props: StepContainerProps) => {
  return (
    <Stack className='h-full'>
      <Stack className='overflow-y-auto' flexGrow={1}>
        {props.toolbarComponent}
        {!props.hideStepper && (
          <>
            <WizardStepper
              activeStep={props.stepperIndex ?? 0}
              stepsLabels={props.stepperLabels ?? []}
            />
            <Divider />
          </>
        )}
        <CardContent
          className='flex-grow'
          sx={{
            padding: '40px',
            ...props.sx,
          }}
        >
          {props.children}
        </CardContent>
      </Stack>
      {!props.hideNavigation && (
        <PanelFooter
          primaryButton={{
            text: props.nextButtonText || 'Next',
            onClick: props.onNextClick,
            disabled: !props.canGoNext(),
            'data-testid': 'wizard-step-next-btn',
          }}
          secondaryButton={{
            text: props.backButtonText || 'Back',
            onClick: props.onBackClick,
            'data-testid': 'wizard-step-back-btn',
          }}
        >
          {props.navigationComponent}
        </PanelFooter>
      )}
    </Stack>
  );
};
