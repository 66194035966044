import {
  TableContainer,
  Table,
  TableRow,
  styled,
  TableCell,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import type { BackupPolicy, BackupVault } from '@repo/api-gw-sdk';
import React from 'react';

import VaultTag from '@/components/vaults/vaultTag';
import { fromDaysToBiggestAsPossible } from '@/utils/dateTime';

import { frequencyCronText } from './BackupPolicyFormatting';

const PolicyTableCell = styled(TableCell)(() => ({
  borderBottom: 'none',
  padding: '2px 10px 2px 0px',
  verticalAlign: 'top',
}));

const PolicyTableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  textTransform: 'uppercase',
  border: 'none',
  padding: 0,
  paddingBottom: '24px',
  color: theme.palette.text.secondary,
}));

interface BackupPolicyRetentionSchedulesTableProps {
  policy: BackupPolicy;
  vaults?: BackupVault[];
}

export default function BackupPolicyRetentionSchedulesTable(
  props: BackupPolicyRetentionSchedulesTableProps
) {
  return (
    <TableContainer className='mb-[12px]'>
      <Table>
        <thead>
          <TableRow>
            <PolicyTableHeaderCell>Frequency</PolicyTableHeaderCell>
            <PolicyTableHeaderCell>Retention</PolicyTableHeaderCell>
            <PolicyTableHeaderCell>Vault</PolicyTableHeaderCell>
          </TableRow>
        </thead>
        <tbody>
          {props.policy.schedules.map((schedule, index, items) => {
            const vault = props.vaults?.find((v) => v.id === schedule.vaultId);
            const formattedRetention =
              schedule && fromDaysToBiggestAsPossible(schedule.backupRetention);

            return (
              <React.Fragment key={schedule.id}>
                <TableRow>
                  <PolicyTableCell>
                    {schedule.windows && frequencyCronText(schedule.windows)}
                  </PolicyTableCell>
                  <PolicyTableCell>
                    {schedule && (
                      <Stack gap='4px'>
                        <Typography>{`${formattedRetention.value} ${formattedRetention.unit}`}</Typography>
                        {formattedRetention.unit !== 'days' && (
                          <Typography>{`(${schedule.backupRetention} days)`}</Typography>
                        )}
                      </Stack>
                    )}
                    {!schedule && '--'}
                  </PolicyTableCell>
                  <PolicyTableCell>
                    {vault && <VaultTag vault={vault} />}
                  </PolicyTableCell>
                </TableRow>
                {index !== items.length - 1 && (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      sx={{ border: 'none', padding: '24px 0' }}
                    >
                      <Divider sx={{ borderStyle: 'dashed' }} />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
}
